import Swal from "sweetalert2/dist/sweetalert2.js";
import { useLocation, Link } from "react-router-dom";
import { Card, Col, Row, Button } from "react-bootstrap";
import { patchData } from "../../../../services/api";
import defaultImage from "../../../../assets/images/no-img.webp";
import { useEffect, useState } from "react";
import "./ModuleCards.scss";

function ModuleCards({ modules, setModules }) {
  const [currentModules, setCurrentModules] = useState(modules);
  const location = useLocation().pathname;

  const haveTest = (start_date, end_date) => {
    if (!start_date || !end_date) return false;
    const now = new Date();
    return now >= new Date(start_date) && now <= new Date(end_date);
  };

  useEffect(() => {
    setCurrentModules(modules);
  }, [modules]);

  const handleDeleteModule = async (id) => {
    const confirmed = await Swal.fire({
      title: "Сигурни ли сте?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Отказ",
      confirmButtonText: "Изтрий",
    });
    if (confirmed.isConfirmed) {
      try {
        const {
          data: { data: newModules },
        } = await patchData(`/modules/delete`, { moduleId: id });
        setModules([...newModules]);
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Failed to delete module",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <Row xs={1} md={3} className="g-3">
      {currentModules?.map((module, idx) => {
        const {
          id,
          preview_image,
          results_count,
          test_start_date,
          test_end_date,
          module_name,
        } = module;
        const imageSrc = preview_image
          ? `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PORT ? `:${process.env.REACT_APP_BACKEND_PORT}` : ''}/${preview_image}`
          : defaultImage;
        return (
          <Col key={idx}>
            <Card className="module-card">
              <Card.Img
                variant="top"
                className="module-card-img"
                alt="module-img"
                src={imageSrc}
              />
              <div className="module-card-action-buttons gap-2">
                {location === "/admin/manage-modules" ? (
                  <>
                    <Link to={`/admin/manage-modules/edit-module?id=${id}`}>
                      <Button
                        variant="outline-light"
                        className="btn-success border-0"
                      >
                        Редактирай
                      </Button>
                    </Link>
                    <Button
                      variant="outline-primary"
                      className="btn-danger border-0"
                      onClick={() => handleDeleteModule(id)}
                    >
                      Премахни
                    </Button>
                  </>
                ) : (
                  <>
                    <Link
                      to={"/module?id=" + id}
                      className="card-btn btn btn-primary"
                    >
                      Отвори
                    </Link>
                    {results_count === 0 &&
                      haveTest(test_start_date, test_end_date) && (
                        <Link
                          to={`/test?moduleId=${id}`}
                          className="card-btn btn btn-primary"
                        >
                          Изпит
                        </Link>
                      )}
                  </>
                )}
              </div>
              <Card.Body>
                <Card.Title>{module_name}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default ModuleCards;
